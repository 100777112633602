

















import { BarChart } from 'vue-chart-3'
import { Chart, registerables } from 'chart.js'
import { defineComponent, ref, computed, Ref } from '@vue/composition-api'

Chart.register(...registerables)

// to-do fix any type later

interface SetUp {
  dataValues: Ref<number[]>
  tabs: Array<unknown>
  index: Ref<number>
  chartData: any
  options: any
  barRef: any
}

const StatiticsChart = defineComponent({
  components: { BarChart },
  setup(): SetUp {
    const dataValues = ref([30, 40, 60, 70, 5, 30, 40, 60, 70, 5, 30, 30])
    const barRef = ref()
    const index = ref(0)
    const tabs = [
      {
        label: 'Total Amount'
      },
      {
        label: 'Total Order Amount'
      }
    ]

    const options = ref<any>({
      scales: {
        bar: {
          type: 'logarithmic',
          position: 'right'
        },
        line: {
          type: 'logarithmic',
          position: 'left'
        }
      },
      plugins: {
        legend: {
          position: 'bottom'
        }
      }
    })

    const chartData = computed<any>(() => ({
      labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
      datasets: [
        {
          type: 'line',
          label: 'Total Order Amount',
          data: dataValues.value,
          borderColor: '#e60000',
          backgroundColor: ['#e60000']
        },
        {
          type: 'bar',
          label: 'Total Order Amount',
          data: dataValues.value,
          backgroundColor: ['#3366cc']
        }
      ]
    }))

    return {
      dataValues,
      chartData,
      options,
      barRef,
      tabs,
      index
    }
  }
})
export default StatiticsChart
