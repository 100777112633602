















import { defineComponent, ref } from '@vue/composition-api'
import QuickAccess from '@/components/QuickAccess/index.vue'
import Calendar from '@/components/Calendar/index.vue'
import StatiticsChart from '@/components/StatiticsChart/index.vue'

const Home = defineComponent({
  components: {
    QuickAccess,
    Calendar,
    StatiticsChart
  },
  setup() {
    const isReload = ref(false)
    return {
      isReload
    }
  }
})
export default Home
