





















































































































































































import {
  defineComponent,
  Ref,
  ref,
  nextTick,
  onMounted,
  ComputedRef,
  computed,
  watch
} from '@vue/composition-api'
import moment from 'moment'
import { api, framebus } from 'plugins'
import { chain, debounce } from 'lodash'
import { Item as ItemRaw, Variety as VarietyRaw, Customer as CustomerRaw } from 'typings'
import { endpoints, query, toCamelCase, showError, formatNumber, frameBusEvent } from 'utils'
import LoaderComponent from '@/components/LoaderComponent/index.vue'
import OneDayDetail from '@/components/Calendar/OneDayDetail.vue'
import SettingViewDialog from '@/components/SettingViewDialog/index.vue'

interface Mode {
  name: string
  text: string
}

interface Detail {
  expectedStems: number
  fmStems: number
  orderStems: number
  harvestStems: number
  packingStems: number
  assignStems: number
  expectedBoxes: number
  fmBoxes: number
  orderBoxes: number
  harvestBoxes: number
  packingBoxes: number
  assignBoxes: number
  assignedBoxes: number
  assignedStems: number
  orderedBoxes: number
  orderedStems: number
  orderedSpecialStems: number
}

interface Size {
  name: string
  detail: Detail
}

interface Variety {
  name: string
  detail: Detail
  children: Array<Size>
}

interface Item {
  name: string
  detail: Detail
  children: Array<Variety>
}

interface DetailDate {
  date: string
  detail: Detail
  items: Array<Item>
}

interface ValueType {
  id: number
  name: string
}

interface SearchItem {
  id: number
  name: string
  text: string
  category: string
}

interface Category {
  name: string
  icon: string
  data: Array<SearchItem>
}

interface RawProp {
  id: number
  name: string
}

interface RawCustomer {
  id: number
  name: string
  code: string
  searchStr: string
}

interface RawData {
  type: number
  date: string
  item: RawProp
  variety: RawProp
  size: RawProp
  unit: RawProp
  quality: RawProp
  customer: RawCustomer
  stems: number
  qualitySize: string
}

interface ClassValueType {
  'value-type-btn_expected_harvest': boolean
  'value-type-btn_FM': boolean
  'value-type-btn_order': boolean
  'value-type-btn_harvest_result': boolean
  'value-type-btn_packing': boolean
  'value-type-btn_assign': boolean
  'value-type-btn__selected': boolean
  'value-type-btn__not_selected': boolean
}

interface SetUp {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  calendar: any
  calendarTitle: Ref<string>
  focus: Ref<string>
  startDate: Ref<string>
  endDate: Ref<string>
  numberOfDay: number
  calendarModes: Mode[]
  selectedMode: Ref<Mode>
  daysBefore: Ref<number>
  isMonthMode: ComputedRef<boolean>
  isShowByBox: Ref<boolean>
  isShowRemaining: Ref<boolean>
  isShowTotal: Ref<boolean>
  rawData: Ref<Array<RawData>>
  detailDates: Ref<DetailDate[]>
  isFocusOneDay: Ref<boolean>
  valueTypes: ValueType[]
  selectedValueType: Ref<number>
  loadingListSearch: Ref<boolean>
  listSearch: Ref<Array<SearchItem>>
  searchInput: Ref<string>
  selectedList: Ref<Array<SearchItem>>
  categoriesList: Ref<Array<Category>>
  onSearching: Ref<boolean>
  onSetDefaultValueType: Ref<boolean>
  newKey: Ref<number>
  updateDetailDates: () => void
  updateCagoriesList: (input: string) => void
  clearSearchValue: () => void
  closeSearch: () => void
  dayFormat: (d: { day: string }) => string
  dayColor: (date: string) => void
  clickDay: (d: { date: string }) => void
  changeMode: () => void
  goNext: (isOneDay: boolean) => void
  goPrev: (isOneDay: boolean) => void
  getValueDate: (date: string) => string
  getClassValueType: (valueType: ValueType) => ClassValueType
  setDefaultMode: (
    selectedValueTypeValue: number,
    isShowTotalValue: boolean,
    isShowByBoxValue: boolean,
    isShowRemainingValue: boolean,
    isSaveAsDefaultValue: boolean,
    daysBeforeValue: number
  ) => void
}

const Calendar = defineComponent({
  props: {
    isReload: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    OneDayDetail,
    LoaderComponent,
    SettingViewDialog
  },
  setup(props, { root, emit }): SetUp {
    const { $toast } = root
    const calendar: any = ref(null)
    const calendarTitle = ref('')
    const numberOfDay = 7
    const numberOneDay = 1
    const daysBefore = ref(0)
    const focus = ref(moment(new Date()).format('YYYY-MM-DD'))
    const startDate = ref(moment(focus.value).format('YYYY-MM-DD'))
    const endDate = ref(
      moment(startDate.value)
        .add(numberOfDay - 1, 'days')
        .format('YYYY-MM-DD')
    )
    const rawData: Ref<Array<RawData>> = ref([])
    const itemsHasOrderNum: Ref<Array<ItemRaw>> = ref([])
    const varietiesHasOrderNum: Ref<Array<VarietyRaw>> = ref([])
    const customersHasOrderNum: Ref<Array<CustomerRaw>> = ref([])
    const isCalledDataHasOrderNum = ref(false)
    const detailDates: Ref<Array<DetailDate>> = ref([])
    const onSetDefaultValueType: Ref<boolean> = ref(false)
    const isShowTotal: Ref<boolean> = ref(true)
    const isShowRemaining: Ref<boolean> = ref(false)
    const isShowByBox: Ref<boolean> = ref(false)
    const calendarModes: Array<Mode> = [
      { name: 'month', text: root.$t('home.calendar.mode.month').toString() },
      { name: 'days', text: root.$t('home.calendar.mode.days').toString() }
    ]
    const selectedMode: Ref<Mode> = ref({
      name: 'days',
      text: root.$t('home.calendar.mode.days').toString()
    })
    const isFocusOneDay: Ref<boolean> = ref(false)
    const valueTypes: Array<ValueType> = [
      { id: 1, name: 'expected_harvest' },
      { id: 2, name: 'FM' },
      { id: 3, name: 'order' },
      { id: 4, name: 'harvest_result' },
      { id: 5, name: 'packing' },
      { id: 6, name: 'assign' }
    ]
    const selectedValueType: Ref<number> = ref(3)
    const searchInput: Ref<string> = ref('')
    const onSearching: Ref<boolean> = ref(false)
    const loadingListSearch: Ref<boolean> = ref(false)
    const listSearch: Ref<Array<SearchItem>> = ref([])
    const selectedList: Ref<Array<SearchItem>> = ref([])
    const categoriesList: Ref<Array<Category>> = ref([])
    const newKey: Ref<number> = ref(1)
    const closeSearch = () => {
      setTimeout(() => {
        onSearching.value = false
      }, 300)
    }

    const getCalendarRecords = async (): Promise<void> => {
      try {
        const queryStr = query.buildQuery({
          start_date: startDate.value,
          end_date: endDate.value
        })
        const response = await api.get(`${endpoints.HOME}calendar_records?${queryStr}`)
        rawData.value = toCamelCase(
          response.data.map((e: any) => ({
            ...e,
            variety: {
              id: e?.variety ? e?.variety.id : null,
              name: e?.variety.name || root.$t('order.no_variety')
            },
            qualitySize: `${e.quality?.name || ''}${e.size?.name || ''}` || root.$t('order.no_size')
          }))
        )
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.get_data_failed') as string)
      }
    }

    const getTotalOfDetail = (values: any, type: number, property: string): number => {
      let res = 0
      values.forEach((e: any) => {
        if (type === e.type && e[property]) {
          res += e[property]
        }
      })
      return res
    }

    // Calculate stem amount which have doesn't have box
    const getSpecialOfDetail = (values: any, type: number): number => {
      let res = 0
      values.forEach((e: any) => {
        if (!e.boxes && type === e.type) {
          res += e.stems
        }
      })
      return res
    }

    const getDetail = (values: any): Detail => {
      // Calculate value of multilevel
      return {
        expectedStems: getTotalOfDetail(values, 1, 'stems'),
        fmStems: getTotalOfDetail(values, 2, 'stems'),
        orderStems: getTotalOfDetail(values, 3, 'stems'),
        harvestStems: getTotalOfDetail(values, 4, 'stems'),
        packingStems: getTotalOfDetail(values, 5, 'stems'),
        assignStems: getTotalOfDetail(values, 6, 'stems'),
        expectedBoxes: getTotalOfDetail(values, 1, 'boxes'),
        fmBoxes: getTotalOfDetail(values, 2, 'boxes'),
        orderBoxes: getTotalOfDetail(values, 3, 'boxes'),
        harvestBoxes: getTotalOfDetail(values, 4, 'boxes'),
        packingBoxes: getTotalOfDetail(values, 5, 'boxes'),
        assignBoxes: getTotalOfDetail(values, 6, 'boxes'),
        assignedBoxes: getTotalOfDetail(values, 5, 'assignedBoxes'),
        assignedStems: getTotalOfDetail(values, 5, 'assignedStems'),
        orderedBoxes: getTotalOfDetail(values, 3, 'orderedBoxes'),
        orderedStems: getTotalOfDetail(values, 3, 'orderedStems'),
        orderedSpecialStems: getSpecialOfDetail(values, 3)
      }
    }

    const updateDetailDates = () => {
      let currentList = rawData.value
      if (selectedList.value.length) {
        chain(selectedList.value)
          .groupBy('category')
          .forEach((values, key) => {
            if (values.length) {
              currentList = currentList.filter(
                (record: RawData) =>
                  values.find(
                    (condition: SearchItem) =>
                      (record[key as keyof RawData] as RawProp)?.id === condition.id
                  ) !== undefined
              )
            }
          })
          .value()
      }
      // fomat data
      detailDates.value = []
      detailDates.value = chain(currentList)
        .groupBy('date')
        .map((values, key) => ({
          date: key,
          detail: getDetail(values),
          items: chain(values)
            .groupBy('item.name')
            .map((item, itemKey) => ({
              name: itemKey,
              detail: getDetail(item),
              children: chain(item)
                .groupBy('variety.name')
                .map((variety, varietyKey) => ({
                  name: varietyKey,
                  detail: getDetail(variety),
                  children: chain(variety)
                    .groupBy('qualitySize')
                    .map((qualitySize, sizeKey) => ({
                      name: sizeKey,
                      detail: getDetail(qualitySize),
                      children: []
                    }))
                    .value()
                }))
                .value()
            }))
            .value()
        }))
        .value()
    }

    const getListSearch = async (input: string | null = null): Promise<void> => {
      newKey.value += 1
      let itemAPI = `${endpoints.ITEMS}has_order_num`
      let varietyAPI = `${endpoints.VARIETIES}has_order_num`
      let customerAPI = `${endpoints.CUSTOMERS}has_order_num`
      if (input) {
        itemAPI = `${endpoints.ITEMS}search_with?search_input=${input.toLowerCase()}`
        varietyAPI = `${endpoints.VARIETIES}search_with?search_input=${input.toLowerCase()}`
        customerAPI = `${endpoints.CUSTOMERS}search_with?search_input=${input.toLowerCase()}`
      }
      listSearch.value = [...selectedList.value]
      loadingListSearch.value = true
      try {
        categoriesList.value = [
          { name: 'item', icon: 'mdi-flower', data: [] },
          { name: 'variety', icon: 'mdi-flower', data: [] },
          { name: 'customer', icon: 'mdi-home', data: [] }
        ]
        let responseItems = null
        let responseVarieties = null
        let responseCustomers = null
        if (!isCalledDataHasOrderNum.value || input) {
          responseItems = await api.get(itemAPI)
          responseVarieties = await api.get(varietyAPI)
          responseCustomers = await api.get(customerAPI)
          if (!isCalledDataHasOrderNum.value) {
            isCalledDataHasOrderNum.value = true
            itemsHasOrderNum.value = toCamelCase(responseItems?.data)
            varietiesHasOrderNum.value = toCamelCase(responseVarieties?.data)
            customersHasOrderNum.value = toCamelCase(responseCustomers?.data)
          }
        }
        const itemsList = input ? toCamelCase(responseItems?.data) : itemsHasOrderNum.value
        itemsList.forEach((item: ItemRaw) => {
          const newItem = {
            id: item.id,
            name: item.name,
            text: item.name,
            category: 'item'
          }
          const isExist = selectedList.value.find(
            (e) => e.category === 'item' && e.id === newItem.id
          )
          const categoryItemList = categoriesList.value.find((e) => e.name === 'item')?.data
          const isExistItem = categoryItemList?.find((e) => e.id === newItem.id)
          let searchResult: any = null
          if (input) {
            searchResult = item.searchStr
              .toLowerCase()
              .match(`^${input.toLowerCase()}|\\|${input.toLowerCase()}`)?.input
            if (searchResult) {
              if (!isExist) {
                listSearch.value.unshift(newItem)
              }
              if (!isExistItem) {
                categoriesList.value.find((e) => e.name === 'item')?.data.unshift(newItem)
              }
            } else if (item.searchStr.toLowerCase().includes(input.toLowerCase())) {
              if (!isExist) {
                listSearch.value.push(newItem)
              }
              if (!isExistItem) {
                categoriesList.value.find((e) => e.name === 'item')?.data.push(newItem)
              }
            }
          } else {
            if (!isExist) {
              listSearch.value.push(newItem)
            }
            if (!isExistItem) {
              categoriesList.value.find((e) => e.name === 'item')?.data.push(newItem)
            }
          }
        })
        const varietiesList = input
          ? toCamelCase(responseVarieties?.data)
          : varietiesHasOrderNum.value
        varietiesList.forEach((variety: VarietyRaw) => {
          const newVariety = {
            id: variety.id,
            name: variety.name,
            text: `${variety.item.name} ${variety.name}`,
            category: 'variety'
          }
          const isExist = selectedList.value.find(
            (e) => e.category === 'variety' && e.id === newVariety.id
          )
          const categoryVarietyList = categoriesList.value.find((e) => e.name === 'variety')?.data
          const isExistVariety = categoryVarietyList?.find((e) => e.id === newVariety.id)
          let searchResult: any = null
          if (input) {
            searchResult = variety.searchStr
              .toLowerCase()
              .match(`^${input.toLowerCase()}|\\|${input.toLowerCase()}`)?.input
            if (searchResult) {
              if (!isExist && searchResult) {
                listSearch.value.unshift(newVariety)
              }
              if (!isExistVariety) {
                categoriesList.value.find((e) => e.name === 'variety')?.data.unshift(newVariety)
              }
            } else if (variety.searchStr.toLowerCase().includes(input.toLowerCase())) {
              if (!isExist) {
                listSearch.value.push(newVariety)
              }
              if (!isExistVariety) {
                categoriesList.value.find((e) => e.name === 'variety')?.data.push(newVariety)
              }
            }
          } else {
            if (!isExist && searchResult) {
              listSearch.value.push(newVariety)
            }
            if (!isExistVariety) {
              categoriesList.value.find((e) => e.name === 'variety')?.data.push(newVariety)
            }
          }
        })
        const customersList = input
          ? toCamelCase(responseCustomers?.data)
          : customersHasOrderNum.value
        customersList.forEach((customer: CustomerRaw) => {
          const newCustomer = {
            id: customer.id,
            name: customer.shortName,
            text: `${customer.code ? customer.code : ''} ${customer.shortName}`,
            category: 'customer'
          }
          const isExist = selectedList.value.find((e: any) => {
            return e.category === 'customer' && e.id === newCustomer.id
          })
          const categoryCustomerList = categoriesList.value.find((e) => e.name === 'customer')?.data
          const isExistCustomer = categoryCustomerList?.find((e) => e.id === newCustomer.id)
          let searchResult: any = null
          if (input) {
            searchResult = customer.searchStr
              .toLowerCase()
              .match(`^${input.toLowerCase()}|\\|${input.toLowerCase()}`)?.input
            if (searchResult) {
              if (!isExist) {
                listSearch.value.unshift(newCustomer)
              }
              if (!isExistCustomer) {
                categoriesList.value.find((e) => e.name === 'customer')?.data.unshift(newCustomer)
              }
            } else if (customer.searchStr.toLowerCase().includes(input.toLowerCase())) {
              if (!isExist) {
                listSearch.value.push(newCustomer)
              }
              if (!isExistCustomer) {
                categoriesList.value.find((e) => e.name === 'customer')?.data.push(newCustomer)
              }
            }
          } else {
            if (!isExist) {
              listSearch.value.push(newCustomer)
            }
            if (!isExistCustomer) {
              categoriesList.value.find((e) => e.name === 'customer')?.data.push(newCustomer)
            }
          }
        })
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.get_data_failed') as string)
      } finally {
        loadingListSearch.value = false
      }
    }

    const updateCagoriesList = debounce(async (input: string) => {
      if (input && input !== searchInput.value) {
        searchInput.value = input
        getListSearch(input)
      }
    }, 300)

    const clearSearchValue = () => {
      searchInput.value = ''
      selectedList.value = []
      getListSearch()
    }

    const isMonthMode = computed(() => {
      return selectedMode.value.name === 'month'
    })

    const dayFormat = (d: { day: string }): string => {
      return d.day
    }

    const dayColor = (date: string) => {
      let style = ''
      if (selectedMode.value.name === 'month') {
        if (focus.value === date) {
          style = 'green-day'
        } else if (moment(focus.value).month() === moment(date).month()) {
          style = 'grey-day'
        }
      }
      return style
    }

    const getValueDate = (date: string): string => {
      let selectedValueTypeName = ''
      switch (selectedValueType.value) {
        case 1:
          selectedValueTypeName = isShowByBox.value ? 'expectedBoxes' : 'expectedStems'
          break
        case 2:
          selectedValueTypeName = isShowByBox.value ? 'fmBoxes' : 'fmStems'
          break
        case 3:
          selectedValueTypeName = isShowByBox.value ? 'orderBoxes' : 'orderStems'
          break
        case 4:
          selectedValueTypeName = isShowByBox.value ? 'harvestBoxes' : 'harvestStems'
          break
        case 5:
          selectedValueTypeName = isShowByBox.value ? 'packingBoxes' : 'packingStems'
          break
        case 6:
          selectedValueTypeName = isShowByBox.value ? 'assignBoxes' : 'assignStems'
          break
        default:
      }

      const num =
        detailDates.value.find((d) => d.date === date)?.detail[
          selectedValueTypeName as keyof Detail
        ] || 0

      const result = num
        ? `${formatNumber.formatThousand(num, false)}${isShowByBox.value ? 'c' : ''}`
        : ''
      return result
    }

    const updateStartEnd = () => {
      if (isMonthMode.value) {
        startDate.value = moment(focus.value).startOf('month').format('YYYY-MM-DD')
        endDate.value = moment(focus.value).endOf('month').format('YYYY-MM-DD')
      } else {
        startDate.value = focus.value
        endDate.value = moment(startDate.value)
          .add(numberOfDay - 1, 'days')
          .format('YYYY-MM-DD')
      }
      getCalendarRecords()
    }

    const getSettingDefault = async (): Promise<void> => {
      try {
        const response = await api.get(`${endpoints.COMMON}get_setting`)
        const settingMember = response.data.setting.home
        if (settingMember) {
          selectedMode.value = calendarModes.find(
            (mode) => mode.name === settingMember.calendar_mode
          ) as Mode
          daysBefore.value = Number(settingMember.days_before) || 0
          selectedValueType.value = valueTypes.find((val) => val.name === settingMember.value_type)
            ?.id as number
          isShowByBox.value = settingMember.is_show_by_box || false
          isShowTotal.value = settingMember.is_show_total || true
          isShowRemaining.value = settingMember.is_show_remaining || false
        }
        if (!isMonthMode.value) {
          focus.value = moment(focus.value).subtract(daysBefore.value, 'days').format('YYYY-MM-DD')
        }
        updateStartEnd()
      } catch (e) {
        showError(e, $toast, root.$t('setting.error.get_default_setting_fail') as string)
      }
    }

    const changeMode = () => {
      onSearching.value = false
      if (selectedMode.value.name !== 'month') {
        isFocusOneDay.value = false
        if (focus.value === moment(new Date()).format('YYYY-MM-DD')) {
          focus.value = moment(focus.value).subtract(daysBefore.value, 'days').format('YYYY-MM-DD')
        }
      }
      nextTick(() => {
        updateStartEnd()
      })
    }

    const goNext = async (isOneDay: boolean) => {
      if (isMonthMode.value) {
        await calendar.value.next()
        focus.value = calendar.value.lastStart.date
      } else if (isOneDay) {
        focus.value = moment(startDate.value).add(numberOneDay, 'days').format('YYYY-MM-DD')
      } else {
        focus.value = moment(startDate.value).add(numberOfDay, 'days').format('YYYY-MM-DD')
      }
      updateStartEnd()
    }

    const goPrev = async (isOneDay: boolean) => {
      if (isMonthMode.value) {
        await calendar.value.prev()
        focus.value = calendar.value.lastStart.date
      } else if (isOneDay) {
        focus.value = moment(startDate.value).subtract(numberOneDay, 'days').format('YYYY-MM-DD')
      } else {
        focus.value = moment(startDate.value).subtract(numberOfDay, 'days').format('YYYY-MM-DD')
      }
      updateStartEnd()
    }

    const clickDay = (d: { date: string }) => {
      focus.value = d.date
      if (isMonthMode.value) {
        updateStartEnd()
        isFocusOneDay.value = true
      }
    }

    const getClassValueType = (valueType: ValueType) => {
      return {
        'value-type-btn_expected_harvest': valueType.name === 'expected_harvest',
        'value-type-btn_FM': valueType.name === 'FM',
        'value-type-btn_order': valueType.name === 'order',
        'value-type-btn_harvest_result': valueType.name === 'harvest_result',
        'value-type-btn_packing': valueType.name === 'packing',
        'value-type-btn_assign': valueType.name === 'assign',
        'value-type-btn__selected': valueType.id === selectedValueType.value,
        'value-type-btn__not_selected': valueType.id !== selectedValueType.value
      }
    }

    const setDefaultMode = async (
      selectedValueTypeValue: number,
      isShowTotalValue: boolean,
      isShowByBoxValue: boolean,
      isShowRemainingValue: boolean,
      isSaveAsDefaultValue: boolean,
      daysBeforeValue: number
    ) => {
      onSetDefaultValueType.value = false
      if (isMonthMode.value) {
        selectedMode.value = calendarModes.find((mode) => mode.name === 'month') as Mode
        selectedValueType.value = selectedValueTypeValue
      } else {
        selectedMode.value = calendarModes.find((mode) => mode.name === 'days') as Mode
        daysBefore.value = daysBeforeValue
      }
      isShowTotal.value = isShowTotalValue
      isShowByBox.value = isShowByBoxValue
      isShowRemaining.value = isShowRemainingValue
      if (isSaveAsDefaultValue) {
        try {
          const param = {
            calendar_mode: isMonthMode.value ? 'month' : 'days',
            value_type: valueTypes.find((val) => val.id === selectedValueType.value)?.name,
            is_show_by_box: isShowByBox.value,
            is_show_total: isShowTotal.value,
            is_show_remaining: isShowRemaining.value,
            days_before: daysBefore.value
          }
          await api.put(`${endpoints.COMMON}update_setting/home`, param)
        } catch (e) {
          showError(e, $toast, root.$t('setting.error.update_default_setting_fail') as string)
        }
      }
    }

    onMounted(() => {
      getSettingDefault()
      nextTick(() => {
        calendarTitle.value = calendar.value.title
      })
    })

    framebus.on(frameBusEvent.UPDATE_CALENDAR, async () => {
      await getCalendarRecords()
    })

    watch(
      () => props.isReload,
      async () => {
        if (props.isReload) {
          await getCalendarRecords()
          emit('reload-finish')
        }
      }
    )

    watch(selectedList, () => {
      searchInput.value = ''
      updateDetailDates()
    })

    watch(rawData, () => {
      updateDetailDates()
    })

    watch(onSearching, (val) => {
      searchInput.value = ''
      if (val) {
        getListSearch()
      }
    })

    return {
      calendarTitle,
      focus,
      startDate,
      endDate,
      numberOfDay,
      calendarModes,
      daysBefore,
      selectedMode,
      isMonthMode,
      isShowByBox,
      isShowTotal,
      isShowRemaining,
      isFocusOneDay,
      valueTypes,
      selectedValueType,
      searchInput,
      listSearch,
      loadingListSearch,
      selectedList,
      categoriesList,
      onSearching,
      closeSearch,
      onSetDefaultValueType,
      updateDetailDates,
      updateCagoriesList,
      clearSearchValue,
      rawData,
      detailDates,
      calendar,
      dayFormat,
      dayColor,
      clickDay,
      goNext,
      goPrev,
      changeMode,
      getValueDate,
      getClassValueType,
      setDefaultMode,
      newKey
    }
  }
})

export default Calendar
