












import { defineComponent } from '@vue/composition-api'
import moment from 'moment'

interface QuickAccessItem {
  name: string
  text: string
  icon: string
}

interface SetUp {
  goToPage: (page: string) => void
  isShowAction: boolean
  items: Array<QuickAccessItem>
}

const QuickAccess = defineComponent({
  setup(props, { root }): SetUp {
    const isShowAction = false
    const thisDate = moment(new Date()).format('YYYY-MM-DD')
    const items = [
      {
        name: 'order.input',
        text: 'home.add_order',
        icon: 'mdi-plus-circle-outline'
      },
      {
        name: 'order.menu_title',
        text: 'home.list_order',
        icon: 'mdi-clipboard-check-outline'
      },
      {
        name: 'invoice.title',
        text: 'home.invoice',
        icon: 'mdi-file-document'
      }
      // {
      //   name: '#',
      //   text: 'home.statistics',
      //   icon: 'mdi-chart-line'
      // }
    ]

    const goToPage = (page: string): void => {
      if (page !== root.$route.name) {
        if (page === 'order.input') {
          root.$router.push({
            name: page,
            params: { auctionDate: thisDate, orderId: '0', detailId: '0' },
            query: { called: 'true' }
          })
        } else if (page === 'order.menu_title') {
          root.$router.push({
            name: page,
            params: { date: thisDate },
            query: { called: 'true' }
          })
        } else if (page === 'invoice.title') {
          root.$router.push({
            name: page,
            params: { date: thisDate },
            query: { called: 'true' }
          })
        } else {
          root.$router.push({
            name: page,
            query: { called: 'true' }
          })
        }
      }
    }
    return {
      goToPage,
      isShowAction,
      items
    }
  }
})
export default QuickAccess
